<template>
  <div class="vehicle-record">
    <div class="record-centent">
       <div class="form-area table-search">
        <el-form
          class="demo-form-inline"
          ref="form"
          :model="form"
          label-position="left"
          label-width="82px"
          :inline="true"
          >         
          <el-form-item label="开始时间：" prop="beginTime">
            <el-date-picker
              v-model="form.beginTime"
              type="date"
              placeholder="选择开始时间"
              :clearable="false"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间：" prop="endTime">
            <el-date-picker
              v-model="form.endTime"
              type="date"
              placeholder="选择结束时间"
              :clearable="false"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="选择线路：">
            <el-select
              clearable
              v-model="form.companyIds"
              multiple
            collapse-tags
            filterable
              placeholder="请选择线路"
            >
              <el-option
                v-for="item in busLineList"
                :key="item.companyId"
                :label="item.companyName"
                :value="item.companyId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="选择司机：">
            <el-select
              clearable
              v-model="form.realityDriverList"
              :filter-method="vehicleMethodFun"
              multiple
              collapse-tags
              filterable
              placeholder="请选择司机"
            >
              <el-option
                v-for="item in driversOptions"
                :key="item.credentialsNo"
                :label="item.driverName"
                :value="item.credentialsNo"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="选择车辆：">
            <el-select
              clearable
              :filter-method="filterMethodFun"
              v-model="form.vehicleNoList"
              multiple
              collapse-tags
              filterable
              placeholder="请选择车辆"
            >
              <el-option
                v-for="item in vehicleOptions"
                :key="item.vehicleNo"
                :label="item.cph"
                :value="item.vehicleNo"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="驾驶状态：">
            <el-select
              clearable
              v-model="form.drivingState"
              placeholder="请选择"
            >
              <el-option
                v-for="item in drivingStateList"
                :label="item.stateValue"
                :value="item.stateCode"
                :key="item.stateCode"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="alarm-btn">
            <el-button size="small" type="primary" @click="search"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <el-table
        :data="tableData"
        height="calc(100vh - 260px)"
        stripe
      >
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="workDay" label="日期"></el-table-column>
        <el-table-column prop="companyName" label="线路"></el-table-column>
        <el-table-column prop="cph" label="车辆"></el-table-column>
        <el-table-column prop="realityDriverName" label="司机"></el-table-column>        
        <el-table-column prop="moveType" label="方向">
          <template slot-scope="scope">
            {{scope.row.moveType === 1?'上行':'下行'}}
          </template>
        </el-table-column>
        <el-table-column prop="leaveTime" label="实际发车"></el-table-column>
        <el-table-column prop="arriveTime" label="实际到达"></el-table-column>
        <el-table-column prop="runMile" label="里程数"></el-table-column>
        <el-table-column prop="times" label="趟次" width="50"></el-table-column>
        <el-table-column prop="natureState" label="变更说明">
          <template slot-scope="scope">{{
              scope.row.natureState | filterNatureState
            }}</template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="pagination">
        <el-pagination
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :current-page.sync="form.currentPage"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          background
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getDriverRecordListNew,queryAllBusLineList,getDriverByline,getBusByline,addDriverRecord,createSettlements,confirmDrivingRecord
} from '@/api/lib/bus-api.js';
import {
  getStartDate,
  getCurentDate,
  checkTimeLimit,
  checkTimeLimitDay,
  checkLimitDate,
  formatDate,
  formatDay,
  getCurent
} from '@/common/utils/index'
export default {
  data(){    
    return{
      form: {
        currentPage: 1,
        pageSize: 10,
        system: "0", //对应系统
        beginTime: new Date(getStartDate()), // 开始时间
        endTime: new Date(getCurentDate()), // 结束时间
        companyIds:[],//选择线路
        realityDriverList:[],//选择司机
        vehicleNoList:[],//选择车辆
        drivingState:'',//驾驶状态
      },
      total: 0,//总条数
      tableData: [],//车辆行驶记录表格数据
      busLineList: [],//线路列表
      driversOptions: [],//驾驶员列表
      vehicleOptions:[],//车辆列表
      drivingStateList:[
        {stateValue:'未确认',stateCode:0},
        {stateValue:'已完成',stateCode:1},
        {stateValue:'未完成',stateCode:2},
      ],
      drivingStateOptions: [
        { content: "完成", value: 1 },
        { content: "未完成", value: 2 }
      ],
      // countTimesOptions: [
      //   { content: "计趟次", value: true },
      //   { content: "不计趟次", value: false }
      // ],
      pickerOptions: {
        disabledDate (time) {
          return checkLimitDate(time)
        },
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      datePickerOptions: {
        disabledDate(time) {
          return (
            time >
            new Date(new Date(`${formatDay(new Date())} 00:00:00`).getTime())
          );
        }
      },
    }
  },
  filters:{
    filterNatureState (item) {
      if (item == 3) {
        return "加油";
      } else if (item == 2) {
        return "交班";
      } 
      else if (item == 1) {
        return "排班";
      }
      else if (item == 4) {
        return "加气";
      } else if (item == 5) {
        return "充电";
      } else if (item == 6) {
        return "维修";
      } else if (item == 7) {
        return "包车";
      } else if (item == 128) {
        return "其他";
      } else if (item == 8) {
        return "恢复运营";
      } else {
        return "正常";
      }
    },
  },
  created () {
    this.queryAllBusLine()
  },
  methods:{
    //模糊查询司机
    vehicleMethodFun(text) {
      getDriverByline({ keyWord: text }).then(res => {
        if (res.code == 1000) {
          this.driversOptions = res.data;
        }
        // else if(res.code===1100){
        //   this.$message.warning(res.msg)
        // }
      });
    },
    // 车辆模糊查询
    filterMethodFun(text) {
      getBusByline({ keyWord: text }).then(res => {
        if (res.code == 1000) {
          this.vehicleOptions = res.data;
        }
      });
    },
    //获取所有线路
    queryAllBusLine () {
      queryAllBusLineList().then((res) => {
        if (res.code === 1000) {
          if (res.data.length == 0) {
            this.$message.warning("还没有线路信息，请先添加线路信息");
          } else {
            this.busLineList = res.data
          }
        }
      })
    },
    // 获取警报信息列表
    getDriverRecordData () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = { ...this.form }
          data.beginTime = formatDate(this.form.beginTime).split(" ")[0]
          data.endTime = formatDate(this.form.endTime).split(" ")[0]
          getDriverRecordListNew(data).then((res) => {
            if (res.code === 1000) {              
              if(res.data.list.length>0){
                res.data.list.map(item=>{
                  if(item.workDay)item.workDay = item.workDay.split(" ")[0];
                  if(item.planLeaveTime)item.planLeaveTime = item.planLeaveTime.split(" ")[1].slice(0,-3);
                  if(item.leaveTime)item.leaveTime = item.leaveTime.split(" ")[1].slice(0,-3);
                  if(item.planArriveTime)item.planArriveTime=item.planArriveTime.split(" ")[1].slice(0,-3);
                  if(item.arriveTime)item.arriveTime = item.arriveTime.split(" ")[1].slice(0,-3);
                })
              }
              this.tableData = res.data.list || [];
              this.total = res.data.total
            } else {
              this.tableData = []
              this.total = 0
            }
          })
        }
      })
    },
    search(){
      this.form.currentPage = 1
      this.getDriverRecordData()
    },
    // 当前页改变
    handleCurrentChange (page) {
      this.form.currentPage = page
      this.getDriverRecordData()
    },
    // 每页显示条数改变
    handleSizeChange (size) {
      this.form.currentPage = 1
      this.form.pageSize = size
      this.getDriverRecordData()
    },
  }
}
</script>
<style lang="scss" scoped>
.alarm-btn{
  width: 400px;
}
</style>